import { AxiosResponse } from "axios";
import { AnyData, TipoCampo } from "../interfaces";
import { apiGateway } from "../repositories/api.gateway";
import { getTokens } from "./data";
import { getAll } from "./db";

/**
 * Converte um tipo de campo do formato Geo360 para um tipo de campo equivalente no banco de dados.
 *
 * @param {TipoCampo} tipoCampoGeo360 - O tipo de campo no formato Geo360.
 * @returns {string} O tipo de campo equivalente no banco de dados.
 *
 * @example
 * const tipoCampoGeo360 = 1; // Representa um campo de texto no formato Geo360
 * const tipoCampoBD = converteTipoCampo(tipoCampoGeo360);
 * console.log("Tipo de campo equivalente no banco de dados:", tipoCampoBD); // Deve imprimir "text"
 */
export function converteTipoCampo(tipoCampoGeo360: TipoCampo): string {
  switch (tipoCampoGeo360) {
    case 1:
      return "text";
    case 2:
      return "integer";
    case 3:
      return "numeric";
    case 5:
      return "integer";
    case 6:
      return "boolean";
    case 7:
      return "date";
    default:
      return "text";
  }
}

/**
 * Obtém a preferência de formulário armazenada para a tabela especificada.
 *
 * @param {string} tabela - O nome da tabela para a qual a preferência de formulário deve ser obtida.
 * @returns {string | null} A preferência de formulário armazenada ou null se não existir.
 */

export function getFormPreference(tabela: string): string | null {
  const xAuthToken = getTokens()["x-auth-token"];
  return localStorage.getItem(`${tabela}#${xAuthToken}`);
}

/**
 * Salva a preferência de formulário para a tabela especificada.
 *
 * @param {string} tabela - O nome da tabela para a qual a preferência de formulário deve ser salva.
 * @param {string} value - O valor da preferência de formulário a ser salvo.
 */

export function saveFormPreference(tabela: string, value: string): void {
  const xAuthToken = getTokens()["x-auth-token"];
  localStorage.setItem(`${tabela}#${xAuthToken}`, value);
}

/**
 * Gera uma nova inscrição cartográfica com base no ID do lote e no histórico de inscrições.
 *
 * @async
 * @param {string} id_lote - O ID do lote para o qual a inscrição cartográfica será gerada.
 * @returns {Promise<string>} A nova inscrição cartográfica gerada.
 *
 * @example
 * const idLote = "123";
 * const novaInscricao = await novaInscricaoCartografica(idLote);
 * console.log("Nova inscrição cartográfica gerada:", novaInscricao);
 */

export async function novaInscricaoCartografica(id_lote: string): Promise<string> {
  try {
    const inscricaoLote = await apiGateway.get(`/rest/lote?id=eq.${id_lote}&select=inscricao_cartografica`);

    const inscricoesAtuais = await apiGateway.get(
      `/rest/imobiliario?id_lote=eq.${id_lote}&select=inscricao_cartografica&order=inscricao_cartografica.asc`,
    );

    let imobiliarioOffline = 0;
    let imobiliario: AnyData[] = getAll("imobiliario")!;

    imobiliario!.forEach((i) => {
      if (typeof i.id == "string") {
        imobiliarioOffline++;
      }
    });

    if (inscricoesAtuais.data.length > 0) {
      const ultimaInscricao = inscricoesAtuais.data.slice(-1)[0].inscricao_cartografica;
      const imobiliarioNaUltimaInscricao = ultimaInscricao.substr(ultimaInscricao.length - 3);
      const novoImobiliarioNaInscricao = Number(imobiliarioNaUltimaInscricao) + (1 + imobiliarioOffline);

      return inscricaoLote.data[0].inscricao_cartografica + String(novoImobiliarioNaInscricao).padStart(3, "0");
    } else {
      return inscricaoLote.data[0].inscricao_cartografica + String(``).padStart(3, "0");
    }
  } catch (error) {
    console.warn("Nao foi possivel determinar a inscricao cartográfica do novo imobiliario", error);

    return "";
  }
}

/**
 * Gera um novo número de cadastro com base no histórico de números de cadastro.
 *
 * @async
 * @returns {Promise<string | null>} O novo número de cadastro gerado, ou null se não for possível obter.
 *
 * @example
 * try {
 *   const novoCadastro = await novoNumeroCadastro();
 *   if (novoCadastro !== null) {
 *     console.log("Novo número de cadastro gerado:", novoCadastro);
 *   } else {
 *     console.warn("Não foi possível gerar o novo número de cadastro.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao gerar o novo número de cadastro:", error);
 * }
 */

export async function novoNumeroCadastro(): Promise<string> {
  let ultimoNumeroCadastro: AxiosResponse<Array<number>>;

  try {
    ultimoNumeroCadastro = await apiGateway.get(`/rest/rpc/next_numero_cadastro`);
  } catch (error) {
    console.warn(`Não foi possível obter o próximo número do cadastro. Verifique se existe uma função para gerar o novo numero_cadastro no banco de dados como no exemplo: 
      
      CREATE OR REPLACE FUNCTION cadastro.next_numero_cadastro()
      RETURNS INTEGER AS
      $$
      DECLARE
          max_numero_cadastro INTEGER;
          new_numero_cadastro INTEGER;
      BEGIN
          -- Get the max value of numero_cadastro from cadastro.imobiliario
          SELECT MAX(numero_cadastro) INTO max_numero_cadastro FROM cadastro.imobiliario;
      
          -- Increment the max value by 1
          new_numero_cadastro := COALESCE(max_numero_cadastro, 0) + 1;
      
          -- Return the new incremented value
          RETURN new_numero_cadastro;
      END;
      $$
      LANGUAGE PLPGSQL; \n\n Ao final, reinicialize as APIs Rest do município para atualizar os endpoints.`);
    return "";
  }

  try {
    let imobiliarioOffline = 0;
    let imobiliario: AnyData[] = getAll("imobiliario")!;

    imobiliario!.forEach((i) => {
      if (typeof i.id == "string") {
        imobiliarioOffline++;
      }
    });

    const novoNumeroCadastro = Number(ultimoNumeroCadastro.data) + imobiliarioOffline;

    return String(novoNumeroCadastro);
  } catch (error) {
    console.warn("Houve um erro ao determinar o novo numero_cadastro", error);
    return "";
  }
}

/**
 * Obtem valores a serem preenchidos por default ao criar um novo imobiliário.
 *
 * @async
 * @returns {Promise<{[key: string] : any} | null>} O novo número de cadastro gerado, ou null se não for possível obter.
 *
 */
export async function getPreenchimentoParaNovoImobiliario(loteId: string): Promise<{ [key: string]: any } | null> {
  try {
    const response = await apiGateway.get(`/rest/rpc/get_preenchimento_para_novo_imobiliario?p_lote_id=${loteId}`);

    if (response.data.length === 0) {
      return {}
    } else {
      return response.data[0]
    }
  } catch (error) {
    console.warn(`Não foi possível obter o próximo número do cadastro. Verifique se existe uma função para gerar o novo numero_cadastro no banco de dados como no exemplo: 
      
      CREATE OR REPLACE FUNCTION cadastro.get_preenchimento_para_novo_imobiliario(p_lote_id INT)
      RETURNS TABLE (
          id_lote INT,
          id_bairro INT,
          id_distrito INT,
          id_setor INT,
          id_quadra INT,
          id_secao INT
      ) AS
      $$
      BEGIN
          RETURN QUERY
          WITH secao_proxima AS (
              SELECT secao.id
              FROM cadastro.secao
              ORDER BY secao.geom <-> (SELECT geom FROM cadastro.lote WHERE id = p_lote_id)
              LIMIT 1
          )
          SELECT 
              lote.id AS id_lote, 
              bairro.id AS id_bairro,
              distrito.id AS id_distrito,
              setor.id AS id_setor,
              quadra.id AS id_quadra,
              secao_proxima.id AS id_secao
          FROM cadastro.lote
          LEFT JOIN cadastro.bairro ON ST_Intersects(lote.geom, bairro.geom)
          LEFT JOIN cadastro.distrito ON ST_Intersects(lote.geom, distrito.geom)
          LEFT JOIN cadastro.setor ON ST_Intersects(lote.geom, setor.geom)
          LEFT JOIN cadastro.quadra ON ST_Intersects(lote.geom, quadra.geom)
          LEFT JOIN secao_proxima ON TRUE
          WHERE lote.id = p_lote_id;
      END;
      $$
      LANGUAGE plpgsql;

      NOTIFY pgrst;`);
    return {}
  };
}

/**
 * Salva a última posição do mapa (centro e zoom) no armazenamento local.
 *
 * @param {{ center: number[]; zoom: number }} lastPosition - A última posição do mapa a ser salva.
 * @returns {void}
 *
 * @example
 * const lastMapPosition = { center: [10, 20], zoom: 12 };
 * saveLastPosition(lastMapPosition);
 */

export function saveLastPosition(lastPosition: { center: number[]; zoom: number }): void {
  localStorage.setItem("lastPosition", JSON.stringify(lastPosition));
}

/**
 * Obtém a última posição do mapa (centro e zoom) do armazenamento local.
 *
 * @returns {{ center: number[]; zoom: number } | undefined} A última posição do mapa, ou undefined se não estiver definida.
 *
 * @example
 * const lastPosition = getLastPosition();
 * if (lastPosition) {
 *   console.log("Última posição do mapa:", lastPosition);
 * } else {
 *   console.warn("Nenhuma posição do mapa encontrada.");
 * }
 */

export function getLastPosition(): { center: number[]; zoom: number } | undefined {
  return localStorage.getItem("lastPosition") ? JSON.parse(localStorage.getItem("lastPosition") || "{}") : null;
}

/**
 * Retorna filtros adicionais de query string com base no município e na tabela fornecidos.
 * 
 * Essa função é útil para aplicar cláusulas específicas em requisições,
 * como filtrar registros inativos em determinados municípios.
 * O retorno pode ser usado diretamente nas URLs de requisição (ex: `&ct_passivo=is.false`).
 *
 * @param {string | null} municipio - Nome do município (pode vir do localStorage).
 * @param {string} table - Nome da tabela que está sendo consultada.
 * @returns {string} Filtro extra formatado como parte da query string, ou uma string vazia se não houver filtro aplicável.
 *
 * @example
 * const municipio = localStorage.getItem("municipio");
 * const filter = getExtraFilter(municipio, "lote");
 * // Pode retornar: "&ct_passivo=is.false"
 */
export function getExtraFilter(municipio: string | null, table: string): string {
  const city = municipio?.toLowerCase();

  const filters: Record<string, Record<string, string>> = {
    maracanau: {
      lote: "&ct_passivo=is.false",
    },
  };

  return filters[city]?.[table] || "";
}