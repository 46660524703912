const variablesByEnvironments: {
  [key: string]: {
    urlApiPlataforma: string;
    urlApiCadastro: string;
    urlApiGateway: string;
    ambiente: string;
  };
} = {
  topocartDev: {
    urlApiPlataforma: "https://plataforma.desenvolvimento.geo360.topocart.dev.br",
    urlApiCadastro: "https://cadastro.desenvolvimento.geo360.topocart.dev.br",
    urlApiGateway: "https://gateway.desenvolvimento.geo360.topocart.dev.br",
    ambiente: "Ambiente de Desenvolvimento - Topocart",
  },
  portoVelho: {
    urlApiPlataforma: "https://plataforma-geo360.portovelho.ro.gov.br",
    urlApiCadastro: "https://cadastro-geo360.portovelho.ro.gov.br",
    urlApiGateway: "https://gateway-geo360.portovelho.ro.gov.br",
    ambiente: "Porto Velho",
  },
  alexania: {
    urlApiPlataforma: "https://plataforma-geo360.alexania.go.gov.br",
    urlApiCadastro: "https://cadastro-geo360.alexania.go.gov.br",
    urlApiGateway: "https://gateway-geo360.alexania.go.gov.br",
    ambiente: "Alexania",
  },
  manaus: {
    urlApiPlataforma: "https://plataforma-geo360.manaus.am.gov.br",
    urlApiCadastro: "https://cadastro-geo360.manaus.am.gov.br",
    urlApiGateway: "https://gateway-geo360.manaus.am.gov.br",
    ambiente: "Manaus",
  },
  next: {
    urlApiPlataforma: "https://plataforma.geo360.dev",
    urlApiCadastro: "https://cadastro.geo360.dev",
    urlApiGateway: "https://gateway.geo360.dev",
    ambiente: "Ambiente de Homologação",
  },
  mesquita: {
    urlApiPlataforma: "https://plataforma.geo360.com.br",
    urlApiCadastro: "https://cadastro.geo360.com.br",
    urlApiGateway: "https://api-gateway.geo360.com.br",
    ambiente: "eTopocart",
  },
};

export const environments = variablesByEnvironments[process.env.REACT_APP_AMBIENTE || "topocartDev"];
