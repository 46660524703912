import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Imobiliario } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { getAll } from "../../../services/db";
import { unirImoveis } from "../../../services/api";
import { apiGateway } from "../../../repositories/api.gateway";
import toast from "react-hot-toast";

interface Props {
  openDialog: boolean;
  handleCloseDialog: () => void;
  fetchAndUpdateDashboardData: (id?: string, parameter?: string) => Promise<void>;
}

export function DialogJoin({handleCloseDialog, openDialog, fetchAndUpdateDashboardData}: Props) {
  const { metadata, setMetadata } = useContext(DashboardContext);
  const [imoveis, setImoveis] = useState<Imobiliario[]>([]);
  const [selectedImovel, setSelectedImovel] = useState<Imobiliario | null>(null);
  const [imoveisIptu, setImoveisIptu] = useState<Record<number, string>>({});
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openMainDialog, setOpenMainDialog] = useState(false);
  const situacoesIptuPermitidas = ["Não consta", "Imune", "Isento", "Cancelado", "Pago"];

  function handleCloseDialogs() {
    setOpenWarningDialog(false);
    setOpenMainDialog(false);
    handleCloseDialog();
  }

  async function fetchSituacaoIptu(idImobiliario: number) {
    try {
      const response = await apiGateway.get(`/rest/situacao_iptu?id_imobiliario=eq.${idImobiliario}`);
      if (response.data.length > 0) {
        return response.data[0].situacao_iptu;
      }
      return null;
    } catch (error) {
      toast.error("Erro ao buscar a situação do IPTU.");
      return null;
    }
  }

  useEffect(() => {
    async function verificarIptuPrincipal() {
      if (openDialog) {
        const situacao = await fetchSituacaoIptu(Number(metadata.id_imobiliario));
        if (situacao && !situacoesIptuPermitidas.includes(situacao)) {
          setOpenWarningDialog(true);
        } else {
          setOpenMainDialog(true);
        }
      }
    }
    verificarIptuPrincipal();
  }, [openDialog, metadata.id_imobiliario]);


  useEffect(() => {
    async function loadImoveis() {
      const imoveisList = getAll("imobiliario")?.filter(
        (imovel: Imobiliario) => imovel.id !== Number(metadata?.id_imobiliario)
      ) as Imobiliario[];
  
      setImoveis(imoveisList);
  
      const iptuStatus: Record<number, string> = {};
      for (const imovel of imoveisList) {
        const situacao = await fetchSituacaoIptu(imovel.id);
        if (situacao) {
          iptuStatus[imovel.id] = situacao;
        }
      }
      setImoveisIptu(iptuStatus);
    }
  
    if (openDialog) {
      loadImoveis();
    }
  }, [metadata, openDialog]);  

  function handleSelectImovel(imovel: Imobiliario) {
    const situacaoImovel = imoveisIptu[imovel.id] || null;
    
    if (!situacoesIptuPermitidas.includes(situacaoImovel) && situacaoImovel !== null) {
      return;
    }
  
    setSelectedImovel((prev) => (prev?.id === imovel.id ? null : imovel));
  }
  

  async function handleJoin() {
    if (selectedImovel) {
      try {
        const resultado = await unirImoveis(metadata.id_imobiliario, Number(selectedImovel.id));
        if (resultado === "success") {
          setSelectedImovel(null);
          handleCloseDialogs();

          await fetchAndUpdateDashboardData(String(selectedImovel.id), 'JoinMaracanau');

          setMetadata((current) => {
            return { ...current };
          });

          toast.success("União realizada com sucesso!");
        } else {
          toast.error("Falha na união.");
        }
      } catch (error) {
        console.error("Erro ao realizar a união:", error);
        toast.error("Ocorreu um erro ao tentar unir os imóveis.");
      }
    } else {
      console.log("Nenhum imóvel selecionado.");
    }
  }

  return (
    <>
      <Dialog open={openWarningDialog} onClose={handleCloseDialogs}>
        <DialogContent>
          <Typography variant="h5">Aviso</Typography>
          <Typography variant="body1" style={{ textAlign: "center", marginTop: "10px" }}>
            O imóvel possui dívida de IPTU em aberto e não pode ser unificado.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs} color="success" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMainDialog} onClose={handleCloseDialogs}>
        <DialogContent>
          <h2 style={{ textAlign: "center" }}>Selecione uma unidade imobiliária para unir ao imóvel</h2>

          <List component="nav">
          {imoveis?.map((imovel: Imobiliario) => {
            const situacao = imoveisIptu[imovel.id] || null;
            const iptuPendencia = situacao !== null && !situacoesIptuPermitidas.includes(situacao);

            return (
              <ListItemButton
                key={imovel.id}
                sx={{
                  borderBottom: "1px solid #5f5f5f",
                  backgroundColor: selectedImovel?.id === imovel.id ? "#4b4b4b" : "inherit",
                  opacity: iptuPendencia ? 0.5 : 1,
                  cursor: iptuPendencia ? "not-allowed" : "pointer"
                }}
                onClick={() => !iptuPendencia && handleSelectImovel(imovel)}
              >
                <ListItemText
                  primary={imovel.inscricao_cartografica}
                  secondary={
                    iptuPendencia
                      ? `IPTU em aberto. Não pode ser unificado. Número da unidade imobiliária: ${imovel.nr_unidade} | Número de Cadastro: ${
                          imovel.numero_cadastro || "Não informado"
                        }`
                      : `Número da unidade imobiliária: ${imovel.nr_unidade} | Número de Cadastro: ${
                          imovel.numero_cadastro || "Não informado"
                        }`
                  }
                />
              </ListItemButton>
            );
          })}
          </List>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button color="success" onClick={handleJoin} variant="contained" disabled={!selectedImovel}>
            Juntar imóveis
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
