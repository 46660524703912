import { useState } from "react";
import { useHistory } from "react-router-dom";
import TabelaAuditoriaSistema from "./TabelaAuditoriaSistema";
import TabelaModificacoesSistema from "./TabelaModificacoesSistema";

export default function Index() {
  const [openTable, setOpenTable] = useState<"auditoria" | "modificacoes" | null>(null);
  const history = useHistory();

  const handleToggle = (table: "auditoria" | "modificacoes") => {
    setOpenTable((prev) => (prev === table ? null : table));

    history.replace({
      pathname: window.location.pathname,
      search: "",
    });
  };

  return (
    <div>
      <TabelaAuditoriaSistema
        isOpen={openTable === "auditoria"}
        onToggle={() => handleToggle("auditoria")}
      />
      <TabelaModificacoesSistema
        isOpen={openTable === "modificacoes"}
        onToggle={() => handleToggle("modificacoes")}
      />
    </div>
  );
}
