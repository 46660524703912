import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Box,
  Collapse,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  useTheme,
  Menu,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { apiGateway } from "../../../repositories/api.gateway";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

const colunas = [
  { value: "tabela", label: "Tabela" },
  { value: "id", label: "ID" },
  { value: "nome", label: "Nome" },
  { value: "aud_criado_por", label: "Criado por" },
  { value: "aud_criado_data", label: "Data Criado" },
  { value: "aud_modificado_por", label: "Modificado por" },
  { value: "aud_modificado_data", label: "Data Modificado" },
];

interface TabelaModificacoesSistemaProps {
  isOpen: boolean;
  onToggle: () => void;
}

export default function TabelaModificacoesSistema({ isOpen, onToggle }: TabelaModificacoesSistemaProps) {
  const [colunaSelecionada, setColunaSelecionada] = useState("");
  const [filtro, setFiltro] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const colunaParam = params.get("coluna") || "";
    const filtroParam = params.get("filtro") || "";

    setColunaSelecionada(colunaParam);
    setFiltro(filtroParam);

    if (colunaParam || filtroParam) {
      handleSearch();
    }
  }, [location.search]);

  function isValidSearch() {
    return colunaSelecionada && filtro;
  }

  function generateQueryParams(currentPage = page, currentRowsPerPage = rowsPerPage) {
    const params = new URLSearchParams();
    const tenantId = atob(localStorage.getItem("xTnToken"));

    params.append("tenant_id", tenantId.toString());
    params.append("data_offset", (currentPage * currentRowsPerPage).toString());
    params.append("data_limit", currentRowsPerPage.toString());

    if (colunaSelecionada && filtro) {
      params.append("filter_column", colunaSelecionada);
      params.append("filter_value", filtro);
    }

    if (orderBy) {
      params.append("order", `${orderBy}.${order}`);
    }

    return params.toString();
  }

  const {
    data: rowsData = { data: [], totalRecords: 0 },
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["modificacoe-sistema", location.search, page, rowsPerPage, orderBy, order],
    queryFn: async () => {
      const queryParams = generateQueryParams();
      const response = await apiGateway(`/plataforma/rest/rpc/get_audit?${queryParams}`);
      const totalRecords = response.data.length > 0 ? parseInt(response.data[0].total_records, 10) : 0;
      const rows = response.data.map(({ total_records, ...rest }) => rest);

      return { data: rows, totalRecords };
    },
    staleTime: 120000,
  });

  function handleSearch() {
    if (isValidSearch()) {
      setPage(0);
      const queryParams = generateQueryParams(0, rowsPerPage);

      history.push({
        pathname: window.location.pathname,
        search: queryParams ? `?${queryParams}` : "",
      });
    }
  }

  function handleRequestSort(property: string) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    refetch();
  }

  function clearFilters() {
    setColunaSelecionada("");
    setFiltro("");
    setPage(0);
    setOrderBy("");
    history.replace(window.location.pathname);
    setTimeout(() => {
      refetch();
    }, 0);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
    refetch();
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    refetch();
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function getAuditoriaExport() {
    const queryParams = generateQueryParams();
    const response = await apiGateway(`/plataforma/rest/rpc/get_audit?${queryParams}`);
    return response.data;
  }

  async function exportConsult(format: string) {
    setIsExporting(true);
    handleClose();

    try {
      const auditoriaExport = await getAuditoriaExport();

      if (!auditoriaExport || auditoriaExport.length < 1) {
        toast("Nenhum item encontrado para o filtro aplicado!");
        setIsExporting(false);
        return;
      }

      if (format === "csv") {
        exportToCSV(auditoriaExport);
        toast.success("Consulta exportada com sucesso!");
      } else if (format === "xlsx") {
        exportToXLSX(auditoriaExport);
        toast.success("Consulta exportada com sucesso!");
      } else {
        toast.error("Formato não suportado!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Um erro inesperado aconteceu, tente novamente!");
    } finally {
      setIsExporting(false);
    }
  }

  function exportToCSV(data: Record<string, any>) {
    const expandedData = data.map((row) => {
      const flattenedRow: Record<string, any> = {};
      Object.keys(row).forEach((key) => {
        if (key != "total_records") {
          if (typeof row[key] === "object" && row[key] !== null) {
            Object.keys(row[key]).forEach((subKey) => {
              if (row[key][subKey] !== null) {
                flattenedRow[`${key}_${subKey}`] = row[key][subKey];
              }
            });
          } else if (row[key] !== null) {
            flattenedRow[key] = row[key];
          }
        }
      });
      return flattenedRow;
    });

    const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{1,6})?)?$/;

    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = row[key];
        if (typeof value === "string" && datePattern.test(value)) {
          const date = new Date(value);
          row[key] = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        }
      });
    });

    const allHeaders = new Set<string>();
    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => allHeaders.add(key));
    });
    const headers = Array.from(allHeaders);

    const csvRows = expandedData.map((row) =>
      headers
        .map((header) => {
          const cellValue = row[header];
          return typeof cellValue === "string" ? `"${cellValue}"` : cellValue;
        })
        .join(","),
    );

    const csvContent = [`"${headers.join('","')}"`, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "auditoria_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function exportToXLSX(data: Record<string, any>) {
    const expandedData = data.map((row) => {
      const flattenedRow: any = {};
      Object.keys(row).forEach((key) => {
        if (key !== "total_records") {
          if (typeof row[key] === "object" && row[key] !== null) {
            Object.keys(row[key]).forEach((subKey) => {
              if (row[key][subKey] !== null) {
                flattenedRow[`${key}_${subKey}`] = row[key][subKey];
              }
            });
          } else if (row[key] !== null) {
            flattenedRow[key] = row[key];
          }
        }
      });
      return flattenedRow;
    });

    const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{1,6})?)?$/;

    expandedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const value = row[key];
        if (typeof value === "string" && datePattern.test(value)) {
          const date = new Date(value);
          row[key] = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(expandedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AuditoriaExport");

    XLSX.writeFile(workbook, "auditoria_export.xlsx");
  }

  if (error) {
    return (
      <Box padding={2} textAlign="center">
        Erro ao carregar os dados: {error.message}
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Box
        onClick={onToggle}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: "16px 24px",
          borderRadius: "0px",
          borderBottom: 1,
          borderColor: theme.palette.divider,
          marginLeft: theme.spacing(4),
          marginRight: theme.spacing(4),
          position: "relative",
        }}
      >
        <Box sx={{ fontWeight: "bold", fontSize: 18 }}>Modificações no sistema e em tabelas genéricas</Box>
        <Box
          sx={{
            position: "absolute",
            left: "600px",
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: 14,
          }}
        >
          Veja todas as criações e modificações de dados no sistema
        </Box>
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box sx={{ width: "100%", overflowX: "auto", padding: "16px" }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" padding={2}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box marginBottom={3} display="flex" gap={2} flexWrap="wrap" alignItems="center">
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel id="select-coluna">Coluna</InputLabel>
                  <Select
                    labelId="select-coluna"
                    value={colunaSelecionada}
                    onChange={(e) => setColunaSelecionada(e.target.value)}
                    label="Coluna"
                  >
                    {colunas.map((coluna) => (
                      <MenuItem key={coluna.value} value={coluna.value}>
                        {coluna.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Filtro"
                  variant="outlined"
                  value={filtro}
                  onChange={(e) => setFiltro(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  sx={{ flex: 1, padding: "8px 0", minWidth: 200 }}
                />

                <Button
                  color="success"
                  onClick={handleSearch}
                  sx={{ width: "143.7px", height: "56px" }}
                  variant="contained"
                  disabled={!isValidSearch()}
                >
                  Filtrar
                </Button>

                <Button
                  color="warning"
                  onClick={clearFilters}
                  sx={{ width: "143.7px", height: "56px" }}
                  variant="contained"
                >
                  Limpar Filtros
                </Button>

                <Button
                  color="secondary"
                  onClick={handleClick}
                  sx={{ height: "56px" }}
                  variant="contained"
                  disabled={isExporting}
                >
                  {!isExporting ? "Exportar Consulta" : "Carregando..."}
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={() => exportConsult("xlsx")}>XLSX</MenuItem>
                  <MenuItem onClick={() => exportConsult("csv")}>CSV</MenuItem>
                </Menu>
              </Box>

              <Table sx={{ minWidth: 750, borderRadius: "8px" }}>
                <TableHead>
                  <TableRow>
                    {colunas.map((coluna) => (
                      <TableCell key={coluna.value} sx={{ padding: "16px" }}>
                        <TableSortLabel
                          active={orderBy === coluna.value}
                          direction={orderBy === coluna.value ? order : "asc"}
                          onClick={() => handleRequestSort(coluna.value)}
                        >
                          {coluna.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsData?.data || rowsData)?.map((row) => (
                    <TableRow key={row.id}>
                      {colunas.map((coluna) => (
                        <TableCell key={coluna.value}>{row[coluna.value] ?? ""}</TableCell>
                      ))}
                    </TableRow>
                  )) || (
                      <TableRow>
                        <TableCell colSpan={colunas.length}>Nenhum dado encontrado</TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </>
          )}

          <TablePagination
            component="div"
            labelRowsPerPage={"Linhas por página"}
            count={rowsData.totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            sx={{ marginTop: 2 }}
          />
        </Box>
      </Collapse>
    </Box>
  );
}