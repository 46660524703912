import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useState, useEffect, useContext } from "react";
import { getData } from "../../../services/api";
import { saveData } from "../../../services/data";
import { DashboardContext } from "../../../providers/Dashboard";
import { AnyData, Tabelas } from "../../../interfaces";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  paddingTop: "2px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  padding: theme.spacing(1, 1, 1, 1),
  paddingLeft: `calc(${theme.spacing(3)})`,
  transition: theme.transitions.create("width"),
  width: "0px",
}));

export default function SearchField(props: { value: string; setValue: React.Dispatch<React.SetStateAction<string>>, tabela: Tabelas; setList: React.Dispatch<React.SetStateAction<AnyData[] | undefined>> }) {
  const { metadata } = useContext(DashboardContext);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const searchData = async () => {
      if (!props.value.trim()) return;
  
      setIsSearching(true);
  
      try {
        const data = await getData(Number(metadata.id_lote), 1, props.tabela, props.value);
  
        if (data) {
          if (Array.isArray(data[props.tabela])) {
            data[props.tabela].forEach((item) => saveData(item, props.tabela));
            props.setList(data[props.tabela]);
          } else {
            props.setList([]);
          }
  
          if (props.tabela === "imobiliario") {
            if (Array.isArray(data.imobiliario_pessoa)) {
              data.imobiliario_pessoa.forEach((item) => saveData(item, "imobiliario_pessoa"));
            }
  
            if (Array.isArray(data.pessoa)) {
              data.pessoa.forEach((item) => saveData(item, "pessoa"));
            }
          }
        } else {
          props.setList([]);
        }
      } catch (error) {
        console.error("Erro ao buscar na API:", error);
      } finally {
        setIsSearching(false);
      }
    };
  
    const timeout = setTimeout(() => {
      searchData();
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [props.value, props.tabela, metadata.id_lote]);  

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Pesquisar"
          inputProps={{ "aria-label": "search" }}
          value={props.value}
          className={props.value ? "search-active" : ""}
          sx={{
            width: props.value ? "200px" : "0px",
            "&:focus": {
              width: "200px",
              borderBottom: "1px solid primary.ligth",
            },
            "&:active": {
              width: "200px",
            },
            "&:hover": {
              width: "200px",
            },
          }}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
      </Search>
    </Box>
  );
}
