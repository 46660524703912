import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useContext, useEffect, useState, useCallback } from "react";
import { DashboardContext } from "../../../providers/Dashboard";
import { desmembrarUnidade } from "../../../services/api";
import { getAll } from "../../../services/db";
import toast from "react-hot-toast";
import { apiGateway } from "../../../repositories/api.gateway";
import _ from "lodash";

interface Props {
  openDialog: boolean;
  handleCloseDialog: () => void;
  fetchAndUpdateDashboardData: (id?: string, parameter?: string) => Promise<void>;
}

export function DialogSplitImobiliario({ openDialog, handleCloseDialog, fetchAndUpdateDashboardData }: Props) {
  const { metadata, setMetadata } = useContext(DashboardContext);
  const [valueTamanho, setValueTamanho] = useState(0);
  const [valueNumUnidade, setValueNumUnidade] = useState("0");
  const [dataUnidade, setDataUnidade] = useState(null);
  const [pessoaSelecionada, setPessoaSelecionada] = useState<string | null>(null);
  const [pessoas, setPessoas] = useState<any[]>([]);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [situacaoIptu, setSituacaoIptu] = useState<string | null>(null);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openMainDialog, setOpenMainDialog] = useState(false);

  function handleCloseDialogs() {
    setOpenWarningDialog(false);
    setOpenMainDialog(false);
    handleCloseDialog();
  }  

  useEffect(() => {
    const situacoesPermitidas = ["Não consta", "Imune", "Isento", "Cancelado", "Pago"];

    const fetchSituacaoIptu = async () => {
      try {
        const response = await apiGateway.get(`/rest/situacao_iptu?id_imobiliario=eq.${metadata.id_imobiliario}`);

        if (response.data.length > 0) {
          setSituacaoIptu(response.data[0].situacao_iptu);
          if (situacoesPermitidas.includes(response.data[0].situacao_iptu)) {
            setOpenMainDialog(true);
          } else {
            setOpenWarningDialog(true);
          }
        } else {
          setOpenMainDialog(true);
        }
      } catch (error) {
        toast.error("Erro ao buscar a situação do IPTU.");
      }
    };

    if (openDialog) {
      fetchSituacaoIptu();
    }
  }, [openDialog, metadata.id_imobiliario]);

  const getDadosFromPessoa = useCallback(async (field: string, isIdPessoa: boolean = false) => {
    try {
      let response;

      if (isIdPessoa) {
        response = await apiGateway.get(`/rest/pessoa?id=eq.${field}`);
        return [response.data];
      }

      const nomeCpf = field.length >= 3 ? field : "default";
      response = await apiGateway.get(
        `/rest/pessoa?or=(nome.ilike.*${nomeCpf}*,cpf_cnpj.ilike.*${nomeCpf}*,codigo.ilike.*${nomeCpf}*)&limit=20`,
      );
      return response.data;
    } catch (error: any) {
      toast.error(`Erro ao buscar pessoas`);
      console.error(error.message);
      throw new Error(error.message);
    }
  }, []);

  const debouncedSearch = useCallback(
    _.debounce(async (field) => {
      setIsLoadingPessoas(true);
      const result = await getDadosFromPessoa(field);
      setPessoas(result || []);
      setIsLoadingPessoas(false);
    }, 500),
    [getDadosFromPessoa],
  );

  useEffect(() => {
    const fetchPessoaVinculada = async () => {
      try {
        const unidade = getAll("imobiliario")?.find((unidade) => unidade.id === Number(metadata.id_imobiliario));

        setDataUnidade(unidade);

        const vinculo = (getAll("imobiliario_pessoa") || []).find(
          (pessoa) => String(pessoa.id_imobiliario) === String(metadata.id_imobiliario),
        );

        if (vinculo?.id_pessoa) {
          const pessoaDetalhes = await getDadosFromPessoa(vinculo.id_pessoa, true);

          if (pessoaDetalhes && pessoaDetalhes.length > 0) {
            setPessoaSelecionada(pessoaDetalhes[0][0]);
          }
        }
      } catch (error) {
        toast.error("Erro ao carregar dados.");
      }
    };

    if (metadata.id_imobiliario) {
      fetchPessoaVinculada();
    }
  }, [openDialog, getDadosFromPessoa]);

  function handlePessoaInputChange(event: any, value: string) {
    if (value.length >= 3) {
      debouncedSearch(value);
    } else {
      setPessoas([]);
    }
  }

  function formatarCpfCnpj(valor) {
    if (!valor) return "";
    const numeros = valor.replace(/\D/g, "");

    const formatos = {
      11: "$1.$2.$3-$4",
      14: "$1.$2.$3/$4-$5",
    };

    const regexes = {
      11: /(\d{3})(\d{3})(\d{3})(\d{2})/,
      14: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    };

    return regexes[numeros.length]
      ? numeros.replace(regexes[numeros.length], formatos[numeros.length])
      : valor;
  }

  async function handleSplitImobiliario() {
    if (!valueNumUnidade || Number(valueNumUnidade) < 0 || !valueTamanho || valueTamanho <= 0 || !pessoaSelecionada) {
      toast.error("Preencha todos os campos corretamente.");
      return;
    }

    const codigoPessoa = (pessoaSelecionada as unknown as { codigo: string }).codigo;
    const idPessoa = (pessoaSelecionada as unknown as { id: string }).id;

    try {
      const resultado = await desmembrarUnidade(dataUnidade.id, valueNumUnidade, valueTamanho, codigoPessoa, idPessoa);

      if (resultado.status === "success") {
        setValueNumUnidade("0");
        setValueTamanho(0);
        handleCloseDialogs();

        await fetchAndUpdateDashboardData(undefined, "SplitMaracanau");

        toast.success("Desmembramento realizado com sucesso!");
      } else {
        toast.error("Falha no desmembramento.");
      }
    } catch (error) {
      console.error("Erro no desmembramento:", error);
      toast.error("Erro ao realizar o desmembramento.");
    }
  }

  return (
    <>
    <Dialog open={openWarningDialog} onClose={handleCloseDialogs}>
        <DialogContent>
          <Typography variant="h5">
            Aviso
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center", marginTop: "10px" }}>
            O imóvel possui dívida de IPTU em aberto e não pode ser desmembrado.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs} color="success" variant="contained">
            OK
          </Button>
        </DialogActions>
    </Dialog>

    <Dialog
      open={openMainDialog}
      onClose={handleCloseDialogs}
      sx={{
        width: "100%",
      }}
    >
      <DialogContent
        sx={{
          width: "100%",
        }}
      >
        <h2 style={{ textAlign: "center", width: "100%" }}>Desmembramento de Imobiliário</h2>

        <TextField
          fullWidth
          variant="filled"
          placeholder="000"
          size="small"
          style={{ marginBottom: "16px" }}
          label={"Digite o número da nova unidade imobiliária"}
          helperText={
            <>
              O número da nova unidade imobiliária corresponde ao final da inscrição cartográfica, por exemplo:
              0102003004
              <b>
                <u>001</u>
              </b>
            </>
          }
          type="number"
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: { appearance: "none", MozAppearance: "textfield" },
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setValueNumUnidade(e.target.value)
          }
        />

        <TextField
          fullWidth
          variant="filled"
          label="Digite a área do novo imóvel"
          size="small"
          type="number"
          helperText={<>O valor da área deve ser dado em metros quadrados (m²)</>}
          style={{ marginBottom: "16px" }}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: { appearance: "none", MozAppearance: "textfield" },
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setValueTamanho(Number(e.target.value))
          }
        />

        <Autocomplete
          options={pessoas}
          getOptionLabel={(option) =>
            `${option.nome || option.nome_legado}`
          }
          onInputChange={handlePessoaInputChange}
          onChange={(event, newValue) => setPessoaSelecionada(newValue)}
          value={pessoaSelecionada}
          loading={isLoadingPessoas}
          noOptionsText="Não existe nenhuma pessoa com o filtro selecionado"
          loadingText="Buscando informações..."
          sx={{ marginTop: "16px" }}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              sx={{ display: "flex", flexDirection: "column", padding: "8px" }}
            >
              <Typography variant="body1">{option.nome || option.nome_legado}</Typography>
              <Typography variant="body2" color="text.secondary">
                CPF/CNPJ: {formatarCpfCnpj(option.cpf_cnpj)} | Código: {option.codigo}
              </Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selecione o proprietário do novo imobiliário"
              variant="filled"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingPessoas ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button color="success" onClick={handleSplitImobiliario} variant="contained" sx={{ marginBottom: "2rem" }}>
          Desmembrar Imobiliário
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
