import { AnyData, Tabelas } from "../interfaces";

/**
 * Limpa todos os dados armazenados no localStorage.
 */
export async function clear() {
  let version = localStorage.getItem(`lastStoredVersion`)
  localStorage.clear();
  localStorage.setItem(`lastStoredVersion`, version!)
}

/**
 * Remove dados específicos do localStorage, incluindo os logs e outras informações relacionadas.
 * Esta função não limpa todo o localStorage, apenas remove chaves específicas.
 */

export async function clearData() {
  localStorage.removeItem("log");
  Object.keys(localStorage).forEach((key) => {
    if (key.includes("@")) {
      localStorage.removeItem(key);
    }
  });
}

/**
 * Remove registros de log armazenados no localStorage.
 * Esta função apaga apenas os registros de log, não afeta outros dados no localStorage.
 */

export async function clearLogs() {
  localStorage.removeItem("log");
}

/**
 * Retorna um identificador de armazenamento combinando um ID com uma tabela.
 *
 * @param {string} id - O ID para armazenamento.
 * @param {Tabelas} tb - A tabela de referência.
 * @returns {string} O identificador de armazenamento gerado.
 *
 * @example
 * // Suponha que você tenha um ID "12345" e a tabela "imobiliario".
 * const result = storageId("12345", "imobiliario");
 * console.log(result); // Outputs: "12345@imobiliario"
 */

function storageId(id: string, tb: Tabelas): string {
  return `${id}@${tb}`;
}

/**
 * Armazena dados no localStorage combinando um ID com uma tabela.
 * Além de armazenar os dados, atualiza a lista e estrutura associada à tabela.
 *
 * @param {string} id - O ID associado aos dados.
 * @param {Tabelas} tabela - A tabela de referência.
 * @param {AnyData} data - Os dados a serem armazenados.
 *
 * @example
 * // Suponha que você tenha um ID "12345", a tabela "imobiliario" e os seguintes dados:
 * const myData = {
 *   id: "12345",
 *   address: "Rua Exemplo, 123",
 *   price: 100000
 * };
 * put("12345", "imobiliario", myData);
 * // No localStorage, uma entrada com a chave "12345@imobiliario" será criada contendo os dados em formato JSON.
 */

export function put(id: string, tabela: Tabelas, data: AnyData) {
  try {
    localStorage.setItem(storageId(id, tabela), JSON.stringify(data));
    saveOnList(tabela, data);
    if (!getStructure(tabela)) saveStructure(tabela, data);
  } catch (error) {
    console.error("Erro ao salvar dado:", error);
  }
}

/**
 * Atualiza uma coluna específica para um conjunto de dados armazenado no localStorage.
 * Após a atualização, também atualiza a lista e a estrutura associada à tabela.
 *
 * @param {string} id - O ID associado aos dados.
 * @param {Tabelas} tabela - A tabela de referência.
 * @param {string} coluna - A coluna/atributo a ser atualizado.
 * @param {any} valor - O novo valor para a coluna especificada.
 *
 * @example
 * // Suponha que os dados originais armazenados sob o ID "12345" e tabela "imobiliario" sejam:
 * // { id: "12345", address: "Rua Exemplo, 123", price: 100000 }
 * patch("12345", "imobiliario", "address", "Rua Atualizada, 456");
 * // Após a chamada da função, os dados atualizados no localStorage seriam:
 * // { id: "12345", address: "Rua Atualizada, 456", price: 100000 }
 */

export function patch(id: string, tabela: Tabelas, coluna: string, valor: any) {
  try {
    const data = localStorage.getItem(storageId(id, tabela));
    if (!data) return;
    const parsedData = JSON.parse(data!);
    parsedData[coluna] = valor;
    localStorage.setItem(storageId(id, tabela), JSON.stringify(parsedData));
    saveOnList(tabela, parsedData);
  } catch (error) {
    console.error("Erro ao salvar dado:", error);
  }
}

function get(tabela: Tabelas, id?: string, coluna?: string) {
  try {
    if (!id) {
      return getList(tabela);
    }

    const data = localStorage.getItem(storageId(id, tabela));

    if (!data) return undefined;
    const parsedData = JSON.parse(data!);

    if (coluna) {
      // console.log('getValue ->',id,tabela,coluna,parsedData[coluna])
      return parsedData[coluna];
    } else {
      // console.log('getOne->',id,tabela,coluna)
      return parsedData;
    }
  } catch (error) {
    console.error("Erro ao recuperar dado:", error);
  }
}

/**
 * Recupera um valor específico de uma coluna para um conjunto de dados armazenado no localStorage.
 *
 * @param {string} id - O ID associado aos dados.
 * @param {Tabelas} tabela - A tabela de referência.
 * @param {string} column - A coluna/atributo a ser recuperada.
 * @returns {string | undefined} O valor da coluna especificada ou undefined se não for encontrado.
 *
 * @example
 * // Suponha que os dados armazenados sob o ID "12345" e tabela "imobiliario" sejam:
 * // { id: "12345", address: "Rua Exemplo, 123", price: 100000 }
 * const address = getValue("12345", "imobiliario", "address");
 * console.log(address); // Outputs: "Rua Exemplo, 123"
 */
export function getValue(id: string, tabela: Tabelas, column: string): string | undefined {
  return get(tabela, id, column);
}

/**
 * Recupera um conjunto de dados completo armazenado no localStorage com base no ID e tabela fornecidos.
 *
 * @param {string} id - O ID associado aos dados.
 * @param {Tabelas} tabela - A tabela de referência.
 * @returns {AnyData | undefined} O conjunto de dados recuperado ou undefined se não for encontrado.
 *
 * @example
 * // Suponha que os dados armazenados sob o ID "12345" e tabela "imobiliario" sejam:
 * // { id: "12345", address: "Rua Exemplo, 123", price: 100000 }
 * const data = getOne("12345", "imobiliario");
 * console.log(data); // Outputs: { id: "12345", address: "Rua Exemplo, 123", price: 100000 }
 */
export function getOne(id: string, tabela: Tabelas): AnyData | undefined {
  return get(tabela, id);
}

/**
 * Recupera todos os conjuntos de dados associados a uma tabela específica do localStorage.
 *
 * @param {Tabelas} tabela - A tabela de referência.
 * @returns {AnyData[] | undefined} Uma lista dos conjuntos de dados recuperados ou undefined se não for encontrada.
 *
 * @example
 * // Suponha que existam vários conjuntos de dados armazenados sob a tabela "imobiliario".
 * const allData = getAll("imobiliario");
 * console.log(allData); // Outputs an array of data sets associated with "imobiliario" table.
 */
export function getAll(tabela: Tabelas): AnyData[] | undefined {
  return get(tabela);
}

/**
 * Remove um conjunto de dados específico armazenado no localStorage com base no ID e tabela fornecidos.
 * Após a remoção, também atualiza a lista associada à tabela.
 *
 * @param {string} id - O ID associado aos dados a serem removidos.
 * @param {Tabelas} tabela - A tabela de referência.
 *
 * @example
 * // Suponha que os dados armazenados sob o ID "12345" e tabela "imobiliario" sejam:
 * // { id: "12345", address: "Rua Exemplo, 123", price: 100000 }
 * del("12345", "imobiliario");
 * // Após a chamada da função, os dados associados ao ID "12345" e tabela "imobiliario"
 * // não estarão mais presentes no localStorage.
 */

export function del(id: string, tabela: Tabelas) {
  try {
    localStorage.removeItem(storageId(id, tabela));
    removeFromList(tabela, id);
  } catch (error) {
    console.error("Erro ao remover dado:", error);
  }
}

function saveOnList(tb: Tabelas, data: AnyData) {
  if (!localStorage.getItem(`list@${tb}`)) {
    localStorage.setItem(`list@${tb}`, JSON.stringify([data]));
    return;
  }

  const storedData = localStorage.getItem(`list@${tb}`);
  if (!storedData) {
    console.error("Unexpected behavior: localStorage item should exist at this point.");
    return;
  }

  const list = JSON.parse(storedData);

  if (Array.isArray(list)) {
    if (list.some((item) => item.id === data.id)) {
      const updatedList = list.map((item) => (item.id === data.id ? data : item));
      localStorage.setItem(`list@${tb}`, JSON.stringify(updatedList));
    } else {
      list.push(data);
      localStorage.setItem(`list@${tb}`, JSON.stringify(list));
    }
  } else {
    console.error("Stored data is not an array.");
  }
}

function removeFromList(tb: Tabelas, id: string) {
  try {
    const storedData = localStorage.getItem(`list@${tb}`);

    if (!storedData) {
      console.error("Unexpected behavior: localStorage item should exist at this point.");
      return;
    }
    const list = JSON.parse(storedData);

    if (Array.isArray(list)) {
      const updatedList = list.filter((item) => String(item.id) !== id);
      localStorage.setItem(`list@${tb}`, JSON.stringify(updatedList));
    } else {
      console.error("Stored data is not an array.");
    }
  } catch (error) {
    console.error("Erro ao remover dado:", error);
  }
}

function getList(tb: Tabelas): AnyData[] | undefined {
  const list = JSON.parse(localStorage.getItem(`list@${tb}`)!) || undefined;
  // console.log('getList ->',tb, list)
  return list;
}

export function saveStructure(tb: Tabelas, data: { [key: string]: any }) {
  let structure: any = {};

  Object.keys(data).forEach((key) => {
    structure[key] = null;
  });

  localStorage.setItem(`structure@${tb}`, JSON.stringify(structure));
}

/**
 * Recupera a estrutura de um conjunto de dados armazenado para uma tabela específica no localStorage.
 * A estrutura retornada é um objeto onde as chaves representam os atributos do conjunto de dados,
 * e os valores são todos 'null', indicando os campos esperados, mas sem os valores reais.
 *
 * @param {Tabelas} tb - A tabela de referência.
 * @returns {{ [key: string]: any }} Um objeto representando a estrutura dos dados associados à tabela fornecida.
 *
 * @example
 * // Suponha que tenhamos armazenado a estrutura sob a tabela "imobiliario" como:
 * // { address: null, price: null }
 * const structure = getStructure("imobiliario");
 * console.log(structure); // Outputs: { address: null, price: null }
 */

export function getStructure(tb: Tabelas): { [key: string]: any } {
  return JSON.parse(localStorage.getItem(`structure@${tb}`)!);
}
